// @flow
import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { getUrlFromEnv } from '../utils/getUrlFromEnvUtil';

export const getLatitudePersonalLoanApplyUrl = (
  options: { subType?: string } = {}
) => {
  let quoteId = null;
  if (typeof window !== 'undefined') {
    const queryParams = queryString.parse(location.search);
    quoteId = queryParams && queryParams.qId;
  }
  
  const baseUrl = getUrlFromEnv('funnel-pl-start-application-button');

  const searchString = queryString.stringify(options);
  if (searchString && quoteId) {
    return `${baseUrl}?${searchString}&qId=${quoteId}`;
  }
  if (quoteId) {
    return `${baseUrl}?qId=${quoteId}`;
  }
  if (searchString) {
    return `${baseUrl}?${searchString}`;
  }
  return baseUrl;
};

const usePersonalLoanApplyUrl = (options: { subType?: string } = {}) => {
  const [partnername, setPartnername] = useState('');
  useEffect(() => {
    /**
     * partnername is currently set in sessionStorage in Layout.js
     * (every page) when coming in from an external LFS partner (society one, rate setter etc.)
     * This needs to be passed on the the Salesforce application on any personal loan apply now links OP-186 */
    if (typeof window !== 'undefined') {
      const savedPartnerName = sessionStorage.getItem('partnername');
      if (savedPartnerName && savedPartnerName !== partnername) {
        setPartnername(savedPartnerName);
      }
    }
  });

  if (partnername) {
    const newOptions = {
      ...options,
      partnername
    };
    return getLatitudePersonalLoanApplyUrl(newOptions);
  } else {
    return getLatitudePersonalLoanApplyUrl(options);
  }
};

export default usePersonalLoanApplyUrl;
