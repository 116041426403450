import React from 'react';
import styled, { css } from 'styled-components';
import classnames from 'classnames';
import { BREAKPOINT, GUTTER } from '../../utils/constants';

const StyledCardGroup = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINT.LG}) {
    flex-direction: row;
    justify-content: center;
  }
`;

const StyledCardGroupItem = styled.div`
  flex: 1 1 auto;
  justify-content: center;
  margin-bottom: ${GUTTER.G30};
  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    margin-bottom: 0;
    margin-right: ${GUTTER.G30};
    &:last-child {
      margin-right: 0;
    }
  }

  // max width when items are not occupying entire row space
  ${({ col }) => {
    if (col > 0) {
      const itemPercentWidth = (100 / col).toFixed(2);
      const itemGutterOffset = Math.round((30 * (col - 1)) / col);
      const itemWidthDef = `calc(${itemPercentWidth}% - ${itemGutterOffset}px)`;
      return css`
        @media (min-width: ${BREAKPOINT.LG}) {
          width: ${itemWidthDef}; // IE11 issue, required for parent's 'justify-content: center;'
          max-width: ${itemWidthDef};
        }
      `;
    }
  }}
`;

const CardGroup = props => {
  return (
    <StyledCardGroup
      className={classnames(`card-group`, props.className)}
      style={props.style}
    >
      {props.children}
    </StyledCardGroup>
  );
};

const CardGroupItem = props => (
  <StyledCardGroupItem
    className={classnames(`card-group__item`, props.className)}
    style={props.style}
    col={props.col}
  >
    {props.children}
  </StyledCardGroupItem>
);

export { CardGroup, CardGroupItem };
