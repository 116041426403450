// @flow

import React, { useState, useEffect } from 'react';
import { Link } from '@latitude/link';
import styled from 'styled-components';
import {
  MARGIN,
  PADDING,
  BUTTON_STYLE,
  FONT_WEIGHT,
  FONT_SIZE,
  FLEX_WRAP,
  BREAKPOINT,
  FLEX_DIRECTION,
  DISPLAY,
  ALIGN_ITEMS,
  FONT_FAMILY,
  JUSTIFY_CONTENT,
  COLOR,
  ALIGN
} from '@latitude/core/utils/constants';
import ListNumbered from '@latitude/list-numbered/ListNumbered';
import Layout from '../../components/layout';
import Metadata from '../../components/Metadata/Metadata';
import { CardGroup, CardGroupItem } from '../../components/CardGroup/CardGroup';
import Section from '../../components/Section/Section';
import { Box } from '../../components/Box/Box';
import Lframe from '../../components/Lframe/Lframe';
import { Heading5, Heading4 } from '../../components/Heading/Heading';
import { List, ListItem } from '../../components/List';
import Modal from '../../components/Modal/Modal';
import SvgInline from '../../components/SvgInline/SvgInline';
import { LINE_HEIGHT } from '../../utils/constants';
import { Text } from '../../components/Text';
import usePersonalLoanApplyUrl from '../../hooks/usePersonalLoanApplyUrl';

type InfoBoxProps = {
  title: string,
  children: React.Node
};

const InfoBox = ({ title, children }: InfoBoxProps) => (
  <Box backgroundColor={COLOR.WHITE} padding={PADDING.P24} height="100%">
    <Title color={COLOR.BLUE_DEEP} align={ALIGN.CENTER}>
      {title}
    </Title>
    {children}
  </Box>
);

const Title = styled(Heading5)`
  font-size: 24px;
  margin-bottom: ${MARGIN.M24};
`;

const StyledBoxTop = styled(Box)`
  padding-bottom: ${PADDING.P32};

  @media (min-width: ${BREAKPOINT.SM}) {
    padding: ${PADDING.P48} 0 ${PADDING.P64};
  }
`;

const StyledLinkContainer = styled(Box)`
  display: ${DISPLAY.FLEX};
  padding-top: ${PADDING.P24};
  justify-content: ${JUSTIFY_CONTENT.CENTER};

  @media (min-width: ${BREAKPOINT.LG}) {
    padding: ${PADDING.P0};
    justify-content: ${JUSTIFY_CONTENT.FLEX_END};
    align-items: ${ALIGN_ITEMS.CENTER};
    height: 100%;
  }
`;

const StyledBoxBottom = styled(Box)`
  padding: ${PADDING.P48} 0 ${PADDING.P48};

  @media (min-width: ${BREAKPOINT.SM}) {
    padding: ${PADDING.P64} 0 ${PADDING.P64};
  }
`;

const HeroContainer = styled(Box)`
  position: relative;
`;

const HeadingContainer = styled(Box)`
  background-color: ${COLOR.BLUE};
  padding: ${PADDING.P16} 0;

  @media (min-width: ${BREAKPOINT.LG}) {
    padding: ${PADDING.P24} 0;
  }
`;

const BasicHeader = styled(Box)`
  height: 48px;

  @media (min-width: ${BREAKPOINT.LG}) {
    height: 72px;
  }
`;

const StyledFooter = styled.footer`
  padding: ${PADDING.P24} 0 ${PADDING.P32};
  background-color: ${COLOR.GREY5};
  font-size: ${FONT_SIZE.SMALL};

  @media (min-width: ${BREAKPOINT.LG}) {
    padding-top: ${PADDING.P32};
  }
`;

const StyledUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: ${MARGIN.M16};
  display: ${DISPLAY.FLEX};
  flex-wrap: ${FLEX_WRAP.WRAP};
  justify-content: ${JUSTIFY_CONTENT.CENTER};

  li {
    padding: 0 ${PADDING.P8};
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    margin-top: 0;
    justify-content: ${JUSTIFY_CONTENT.FLEX_START};

    li {
      padding: 0 ${PADDING.P16} 0 0;
    }
  }
`;

const StyledDisclaimerText = styled.p`
  text-align: ${ALIGN.CENTER};
  line-height: ${LINE_HEIGHT.TIGHT.SMALL};
  margin: 0;
  @media (min-width: ${BREAKPOINT.LG}) {
    margin-bottom: 7px;
    text-align: ${ALIGN.LEFT};
  }
`;

const StyledPadlockContainer = styled.div`
  display: ${DISPLAY.FLEX};
  justify-content: ${JUSTIFY_CONTENT.CENTER};
  align-items: ${ALIGN_ITEMS.FLEX_END};

  @media (min-width: ${BREAKPOINT.LG}) {
    margin-right: 12px;
  }
`;

const StyledFooterInner = styled.div`
  @media (min-width: ${BREAKPOINT.LG}) {
    display: ${DISPLAY.FLEX};
    flex-direction: ${FLEX_DIRECTION.ROW};
  }
`;

const StyledHomeLink = styled(Link)`
  display: flex;
  align-items: center;
  width: 164px;

  // required inside flex container for IE11
  svg.home-button__logo {
    display: block;
    flex: 0 0 auto;
    width: 164px;
    height: 27px;
  }
`;

const StyledButtonContainer = styled.div`
  max-width: 255px;
  margin: 0 auto;

  @media (min-width: ${BREAKPOINT.LG}) {
    max-width: none;
  }
`;

type Props = {};

// eslint-disable-next-line flowtype/require-parameter-type
const Apply = (props: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState('');
  let applyUrl = usePersonalLoanApplyUrl();
  // This hook is important so that view gets the up to date url
  useEffect(() => {
    setUrl(applyUrl);
  }, [applyUrl]);
  return (
    <Layout location={props.location} noHeader noFooter>
      <BasicHeader>
        <div className="container h-100 d-flex">
          <StyledHomeLink
            noStyle
            href="/"
            trackId="nav-home"
            trackEventData={{
              label: 'Latitude',
              location: 'header'
            }}
            css={`
              display: flex;
              align-items: center;
            `}
          >
            <SvgInline
              name="logo-combination"
              aria-label="Home page"
              className="home-button__logo"
              fillBlueBright
            />
          </StyledHomeLink>
        </div>
      </BasicHeader>
      <main>
        <Box backgroundColor={COLOR.GREY6}>
          <Metadata
            title="Personal Loan Application"
            canonical="/loans/apply"
            noIndex
          />

          <HeadingContainer>
            <div className="container">
              <Heading4
                color={COLOR.WHITE}
                marginBottom={MARGIN.M0}
                fontFamily={FONT_FAMILY.TITLE}
                fontWeight={FONT_WEIGHT.BOLD}
              >
                Latitude Personal Loan application
              </Heading4>
            </div>
          </HeadingContainer>
          <HeroContainer>
            <Lframe personalLoanApply />
          </HeroContainer>

          <Section className="w-100" squashed>
            <StyledBoxTop>
              <div className="row pt-lg-30">
                <div className="col-12 offset-lg-4 col-lg-4">
                  <StyledButtonContainer>
                    <Link
                      button={BUTTON_STYLE.SECONDARY}
                      href={url}
                      trackId="start-application-top"
                      trackEventData={{
                        location: 'top'
                      }}
                    >
                      Start Application
                    </Link>
                  </StyledButtonContainer>
                </div>
                <div className="col-12 col-lg-4">
                  <StyledLinkContainer>
                    <Text fontSize={FONT_SIZE.LARGE}>
                      <Link
                        trackId="loan-details"
                        href="#"
                        onClick={() => setShowModal(true)}
                      >
                        View Loan Details
                      </Link>
                    </Text>
                  </StyledLinkContainer>
                </div>
              </div>
            </StyledBoxTop>
            <CardGroup>
              <CardGroupItem col={3}>
                <InfoBox title="Who can apply?">
                  <Text fontWeight={FONT_WEIGHT.BOLD} marginBottom={MARGIN.M8}>
                    You must be:
                  </Text>
                  <List itemMarginBottom={MARGIN.M8}>
                    <ListItem>
                      a permanent resident of Australia, who is at least 18
                      years old
                    </ListItem>
                    <ListItem>
                      currently employed on a permanent basis and have a regular
                      income
                    </ListItem>
                    <ListItem>
                      able to demonstrate a good credit history for the last 5
                      years
                    </ListItem>
                    <ListItem>
                      free from bankruptcy for the last 7 years
                    </ListItem>
                  </List>
                </InfoBox>
              </CardGroupItem>
              <CardGroupItem col={3}>
                <InfoBox title="How the process works">
                  <ListNumbered
                    alignItems={ALIGN_ITEMS.FLEX_START}
                    data={[
                      'Apply in less than 10 minutes and receive an initial response in 60 seconds.',
                      'We’ll call to let you know if you have been approved, or if we need any further documentation. This may take 1–2 business days.',
                      'Accept your contract online. You’ll get your funds within 24 – 48 hours.'
                    ]}
                    viewStyle="compact"
                    counterBgColor={COLOR.BLUE_DEEP}
                    counterVerticalAlign
                    separator="light"
                  />
                </InfoBox>
              </CardGroupItem>
              <CardGroupItem col={3}>
                <InfoBox title="What will you need?">
                  <List itemMarginBottom={MARGIN.M8}>
                    <ListItem>Proof of identity</ListItem>
                    <ListItem>Your income and expense information</ListItem>
                    <ListItem>Details of your assets and debts</ListItem>
                  </List>
                </InfoBox>
              </CardGroupItem>
            </CardGroup>

            <StyledBoxBottom>
              <div className="row pt-lg-30">
                <div className="col-12 offset-lg-4 col-lg-4">
                  <StyledButtonContainer>
                    <Link
                      button={BUTTON_STYLE.SECONDARY}
                      href={url}
                      trackId="start-application-bottom"
                      trackEventData={{
                        location: 'bottom'
                      }}
                    >
                      Start Application
                    </Link>
                  </StyledButtonContainer>
                </div>
              </div>
            </StyledBoxBottom>
          </Section>
          <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            title="Loan Details"
            content={
              <>
                <List>
                  <ListItem>Secured and unsecured loans</ListItem>
                  <ListItem>Fixed rate for the life of the loan</ListItem>
                  <ListItem>Loan amounts from $3,000</ListItem>
                  <ListItem>Weekly, fortnightly, monthly repayments</ListItem>
                  <ListItem>
                    Choose the day of the week your repayments are deducted
                  </ListItem>
                  <ListItem>1 – 7 year loan terms</ListItem>
                  <ListItem>
                    You can make additional payments at any time, however an
                    early termination fee may apply if the loan is repaid within
                    the first half of your loan term.
                  </ListItem>
                </List>
                <div className="row pt-5">
                  <div className="col-7 col-md-5 col-lg-3 mx-auto">
                    <Link
                      button={BUTTON_STYLE.SECONDARY}
                      trackId="modal-close"
                      onClick={() => {
                        setShowModal(false);
                      }}
                    >
                      Close
                    </Link>
                  </div>
                </div>
              </>
            }
            ariaHideApp={false}
          />
        </Box>
      </main>
      <StyledFooter>
        <div className="container">
          <StyledFooterInner>
            <StyledPadlockContainer>
              <SvgInline name="padlock" aria-label="padlock" fillBlueBright />
            </StyledPadlockContainer>
            <div>
              <StyledUl>
                <li>
                  <Link href="/privacy/">
                    Privacy & Credit Reporting Policy
                  </Link>
                </li>
                <li>
                  <Link href="/terms-and-conditions/">
                    Website Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link href="/security/">Security</Link>
                </li>
              </StyledUl>
              <StyledDisclaimerText>
                Credit provided by Latitude Personal Finance Pty Ltd ABN 54 008
                443 810. Australian Credit Licence Number 392163.
              </StyledDisclaimerText>
            </div>
          </StyledFooterInner>
        </div>
      </StyledFooter>
    </Layout>
  );
};
export default Apply;
