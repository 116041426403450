// @flow

import React from 'react';
import styled from 'styled-components';
import { BREAKPOINT, CARD_COLOR } from '../../utils/constants';

type Props = {
  /** L frame with simple L shape graphic only */
  simple?: boolean,
  /** L frame for home page. */
  home?: boolean,
  /** L frame for loans page. */
  loans?: boolean,
  /** L frame for credit cards page. */
  cards?: boolean,
  /** L frame for infinity rewards page. */
  infinity?: boolean,
  /** L frame for compare our loans page. */
  compareLoans?: boolean,
  /** L frame for PL calculator page. */
  personalLoanRepaymentCalculator?: boolean,
  /** L frame for personal loans page. */
  personalLoans?: boolean,
  /** L frame for low rate. */
  cardLowRate?: boolean,
  /** L frame for pay it faster page. */
  payItFaster?: boolean,
  /** L frame for car loan page. */
  carLoans?: boolean,
  /** L frame for boat loan page. */
  boatLoans?: boolean,
  /** L frame for caravan loan page. */
  caravanLoans?: boolean,
  /** L frame for commercial vehicle loan page. */
  commercialVehicleLoans?: boolean,
  /** L frame for bike loan page. */
  motorbikeLoans?: boolean,
  /** L frame for debt consolidation page. */
  debtConsolidationLoan?: boolean,
  /** L frame for travel loan page. */
  travelLoan?: boolean,
  /** L frame for home renovation loan page. */
  homeReno?: boolean,
  /** If page has a sticky cta, need to drop the lframe so it does not get cut off */
  hasMobileStickyCta?: boolean,
  /** L frame for make better things happen page. */
  betterThings?: boolean,
  /** L frame for determine your rate page. */
  determineRate?: boolean,
  /** L frame for payanyway page. */
  payAnyWay?: boolean,
  /** L frame for Instant Provisioning page. */
  instantProvisioning?: boolean,
  /** L frame for Calculators page. */
  calculators?: boolean,
  /** L frame for Budget Planner page. */
  budgetPlanner?: boolean,
  /** L frame for Car Loan Repayment Calculator page. */
  carLoanRepaymentCalculator?: boolean,
  /** L frame for Contact Us page. */
  contactUs?: boolean,
  /** L frame for Personal Loan Apply page. */
  personalLoanApply?: boolean,
  /** L frame for Personal Loan Broker page. */
  brokers?: boolean,
  financialHardship?: boolean,
  /** L frame for Personal Loan Kiwibank page. */
  personalLoansKiwibank?: boolean,
  /** L frame for Car Loan Kiwibank page. */
  carLoansKiwibank?: boolean,
  /** L frame for Debt Consolidation Loan Kiwibank page. */
  debtConLoansKiwibank?: boolean,
  /** L frame for Home Renovation Loan Kiwibank page. */
  homeLoansKiwibank?: boolean,
  /** L frame for Travel Loan Kiwibank page. */
  travelLoansKiwibank?: boolean,
  /** L frame for Wedding Loan Kiwibank page. */
  weddingLoansKiwibank?: boolean
};

const Lframe = (props: Props) => {
  const defaultBgPosSM_X = `calc(100vw - ${BREAKPOINT.SM} - ((100vw - ${BREAKPOINT.SM} ) / 2) + 15px )`;
  const calculatorPageStyles = `
        background-position: 25px 0;
        z-index: auto;

        @media (min-width: ${BREAKPOINT.MD}) {
          background-position: 54vw 0;
        }

        @media (min-width: ${BREAKPOINT.LG}) {
          background-size: 1250px auto;
          background-position: 50vw 0;
        }

        @media (min-width: ${BREAKPOINT.XL}) {
          background-position: 53vw 0;
        }`;

  const L = styled.div`
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        width: 100vw;
        height: 1237px;
        background-repeat: no-repeat;
        pointer-events: none;

        background-size: 720px;
        background-position: 15px 0;

        @media (min-width: ${BREAKPOINT.SM}) {
          background-position: ${defaultBgPosSM_X} 0;
        }

        @media (min-width: ${BREAKPOINT.MD}) {
          background-size: 992px;
          background-position: 50vw 0;
        }

        @media (min-width: ${BREAKPOINT.LG}) {
          background-size: 1200px;

        }

      /* Home */
      ${(props: Props) =>
        props.home &&
        `background-image: url(${require('./images/lframe-home.png')});
        @media (max-width: ${BREAKPOINT.MD}) {
          top: -80px;
        }
        @media (min-width: ${BREAKPOINT.MD}) {
          top: -48px;
          background-position: 50vw 24px;
        }
        `}

      /* Loans */
      ${(props: Props) =>
        props.loans &&
        `
          background-image: url(${require('./images/lframe-loans.png')});
          height: 1267px;
          background-size: 770px;
        `}

      /* Pay it fast */
      ${(props: Props) =>
        props.payItFaster &&
        `background-image: url(${require('./images/lframe-pay-it-faster.png')});`}

      /* Compare Our Loans */
      ${(props: Props) =>
        props.compareLoans &&
        `background-image: url(${require('./images/lframe-compare-loans.png')});`}

      /* Pay Any Way */
      ${(props: Props) =>
        props.payAnyWay &&
        `background-image: url(${require('./images/lframe-pay-any-way.png')});`}

      /* Personal Loan */
      ${(props: Props) =>
        props.personalLoans &&
        `background-image: url(${require('./images/lshape-blue.svg')});`}

      /* Car Loan */
      ${(props: Props) =>
        props.carLoans &&
        `
          background-image: url(${require('./images/lframe-new-and-used-car.png')});
          @media (max-width: ${BREAKPOINT.LG}) {
            top: 30px;
          }
        `}

      /* Cards */
      ${(props: Props) =>
        props.cards &&
        `background-image: url(${require('./images/lframe-cards.png')});`}

      /* Instant provisioning */
      ${(props: Props) =>
        props.instantProvisioning &&
        `background-image: url(${require('./images/lframe-instant-provisioning.png')});`}

      /* Infinity */
      ${(props: Props) =>
        props.infinity &&
        `background-image: url(${require('./images/lshape-purple.svg')});`}

      /* Card low rate */
      ${(props: Props) =>
        props.cardLowRate &&
        `background-image: url(${require('./images/lshape-light-blue.svg')});`}

      /* Simple */
      ${(props: Props) =>
        props.simple &&
        `
        @media (min-width: 0) {
          max-height: 280px;
          background-position: 50vw 0;
        }
        `}

      /* PL calculator */
      ${(props: Props) =>
        props.personalLoanRepaymentCalculator &&
        `
          background-image: url(${require('./images/lframe-calculator-personal-loan-repayment.png')});
          ${calculatorPageStyles}
        `}

      ${(props: Props) =>
        props.carLoanRepaymentCalculator &&
        `
          background-image: url(${require('./images/lframe-calculator-car-loan-repayment.png')});
          ${calculatorPageStyles}
        `}

      ${(props: Props) =>
        props.budgetPlanner &&
        `
          background-image: url(${require('./images/lframe-calculator-budget-planner.png')});
          ${calculatorPageStyles}
          @media (min-width: ${BREAKPOINT.LG}) {
            background-size: 880px auto;
          }
        `}

      ${(props: Props) =>
        props.calculators &&
        `
          background-image: url(${require('./images/lframe-calculator.png')});
        `}

      /* boat loan */
      ${(props: Props) =>
        props.boatLoans &&
        `background-image: url(${require('./images/lframe-boat-loan.png')});`}

      /* caravan loan */
      ${(props: Props) =>
        props.caravanLoans &&
        `background-image: url(${require('./images/lframe-caravan-loan.png')});`}

      /* motor bike loan */
      ${(props: Props) =>
        props.motorbikeLoans &&
        `background-image: url(${require('./images/lframe-motor-bike-loan.png')});`}

      /* commercial vehicle loan */
      ${(props: Props) =>
        props.commercialVehicleLoans &&
        `background-image: url(${require('./images/lframe-commercial-vehicle-loan.png')});`}

      /* debt consolidation */
      ${(props: Props) =>
        props.debtConsolidationLoan &&
        `background-image: url(${require('./images/lframe-debt-con.png')});`}

      /* travel loan */
      ${(props: Props) =>
        props.travelLoan &&
        `background-image: url(${require('./images/lframe-travel-loan.png')});`}

      /* home reno loan */
      ${(props: Props) =>
        props.homeReno &&
        `background-image: url(${require('./images/lframe-home-reno-loan.png')});`}

      /* Make better things happen */
      ${(props: Props) =>
        props.betterThings &&
        `
          background-image: url(${require('./images/lframe-better-things.png')});
          @media (max-width: ${BREAKPOINT.LG}) {
            top: 36px;
          }
        `}

      /* Determine your rate */
      ${(props: Props) =>
        props.determineRate &&
        `
          background-image: url(${require('./images/lframe-determine-rate.png')});
          background-size: 850px !important;
          @media (max-width: ${BREAKPOINT.LG}) {
            background-size: 650px !important;
          }
        `}

      /* Contact us */
      ${(props: Props) =>
        props.contactUs &&
        `background-image: url(${require('./images/lframe-contact-us.png')});`}

      /* Financial hardship */
      ${(props: Props) =>
        props.financialHardship &&
        `
          background-image: url(${require('./images/lframe-financial-hardship.png')});
          @media (max-width: ${BREAKPOINT.LG}) {
            top: 30px;
          }
        `}

      /* Personal Loan Apply */
      ${(props: Props) =>
        props.personalLoanApply &&
        `
          background-image: url(${require('./images/lframe-apply.png')});
          position: relative;
          background-size: 491px;
          height: 130px;
          width: 100%;
          background-color: ${CARD_COLOR.BLUE_LIGHTER};

          @media (min-width: ${BREAKPOINT.XS}) {
            background-size: 550px;
            height: 145px;
          }

          @media (min-width: ${BREAKPOINT.SM}) {
            background-position: top right;
          }

          @media (min-width: ${BREAKPOINT.MD}) {
            background-size: 750px;
            height: 195px;
          }

          @media (min-width: ${BREAKPOINT.LG}) {
            background-size: 900px;
            height: 235px;
          }

          @media (min-width: ${BREAKPOINT.XL}) {
            background-size: 1150px;
            height: 240px;
          }
        `}

      /* Broker page */
      ${(props: Props) =>
        props.brokers &&
        `
          z-index: 0;
          background-image: url(${require('./images/lframe-brokers.png')});
          @media (max-width: ${BREAKPOINT.LG}) {
            top: 32px;
          }
        `}

        /* Personal Loan Kiwibank */
        ${(props: Props) =>
          props.personalLoansKiwibank &&
          `background-image: url(${require('./images/lframe-kiwibank-personal-loans.webp')});`}

        /* Car Loan Kiwibank */
        ${(props: Props) =>
          props.carLoansKiwibank &&
          `background-image: url(${require('./images/lframe-kiwibank-car-loans.webp')});`}

        /* Debt Consolidation Kiwibank */
        ${(props: Props) =>
          props.debtConLoansKiwibank &&
          `background-image: url(${require('./images/lframe-kiwibank-debt-consolidation.webp')});`}

        /* Home Renovation Loan Kiwibank */
        ${(props: Props) =>
          props.homeLoansKiwibank &&
          `background-image: url(${require('./images/lframe-kiwibank-home-renovation.webp')});`}

        /* Travel Loan Kiwibank */
        ${(props: Props) =>
          props.travelLoansKiwibank &&
          `background-image: url(${require('./images/lframe-kiwibank-travel-loans.webp')});`}

        /* Wedding Loan Kiwibank */
        ${(props: Props) =>
          props.weddingLoansKiwibank &&
          `background-image: url(${require('./images/lframe-kiwibank-wedding-loans.webp')});`}


      /*
       * Following styles are global overrides, should be kept at bottom
       */

      /* If page has a sticky cta, need to drop the lframe so it does not get cut off */
      ${(props: Props) =>
        props.hasMobileStickyCta &&
        `
        @media (max-width: ${BREAKPOINT.LG}) {
          top: 48px;
        }
      `}

      // end of L styled.div styles
  `;

  return <L {...props} />;
};

export default Lframe;
